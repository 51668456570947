import React, { useState, useEffect } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItem from "./listItem";
import { getObjkts } from '../utils/objktsData';
import { concatGalleriesData, getGalleries } from '../utils/galleriesData';

const List = ({ objkts, galleries }) => {
  // const data = useStaticQuery(graphql`
  //   query NFTImageQuery {
  //     allCreatedObjkt {
  //       nodes {
  //         artifact_uri
  //         creator {
  //           name
  //           address
  //         }
  //         objktId
  //         title
  //         mime
  //         swaps {
  //           amount
  //           amount_left
  //           price
  //           status
  //         }
  //         supply
  //       }
  //     }
  //   }
  // `);

  // console.log('QUery', data);
  // if (data && data.allCreatedObjkt) {
  //   data.allCreatedObjkt.nodes.forEach(element => {
  //     if (element.mime && !element.mime.includes('image')) {
  //       console.log('Objkt', element);
  //     }
  //   });
  // }
  // const itemData = data.allCreatedObjkt.nodes;
  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    if (objkts) {
      getObjkts(objkts).then((result) => {
        setItemData(result);
      }).catch((error) => console.error(error));
    }
  }, [objkts]);

  useEffect(() => {
    if (galleries) {
      getGalleries(galleries).then((result) => {
        const data = concatGalleriesData(result);
        setItemData(data);
      }).catch((error) => console.error(error));
    }
  }, [galleries]);
  const matches = useMediaQuery('(min-width:750px)');

  return (
    <ImageList variant="masonry" cols={matches ? 3 : 1} gap={8}>
      {itemData.map((item, index) => {

        if (!item) {
          return (<></>);
        }
        return (
          <ImageListItem key={`${item?.id}-${index}`}>
            <ListItem itemData={item} />
          </ImageListItem>
        )
      })}
    </ImageList>
  );
}

export default List;