import fetchGraphQlData from './fetchGraphql';
import queryObjkt from '../queries/queryObjkt';

export const getCsvData = () => {
  return new Promise((resolve, reject) => {
    fetch('https://sheets.googleapis.com/v4/spreadsheets/1CMjIN3QxgIHoPxqbNa0yifPFv-XgIGy2u8kYyVJU0PY/values/Objtks?key=AIzaSyCSjN9Zib2W3zF1GCDinMusLJQMYpUhMuY')
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log('ResponseJson', responseJson);
        const result = [];
        if (responseJson.values) {
          const head = responseJson.values.shift();

          responseJson.values.forEach((element, index) => {
            const obj = {};
            result.push(obj[head[0]] = element[0]);
          });
        }

        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getObjtkData = (objktId) => {
  return new Promise((resolve, reject) => {
    fetchGraphQlData(queryObjkt, "Objkt", { "id": objktId }).then((result) => {
      if (result.data && result.data.hic_et_nunc_token_by_pk) {
        resolve(result.data.hic_et_nunc_token_by_pk);
      } else {
        reject(`empty ObjtkData ${objktId}`);
      }
    }).catch((error) => {
      reject(error);
    });
  });
}

export const getObjkts = (fileContent) => {
  return new Promise((resolve, reject) => {
    Promise.all(fileContent.map(data => {
      if (data) {
        return getObjtkData(data)
      }
    })).then((result) => {
      resolve(result);
    }).catch((error) => {
      reject(error);
    });
  });
}
