import React from 'react';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const CustomGridItem = styled(Grid)`
  & .MuiGrid-item {
    padding-top: 0;
  }
`;

const AudioItem = ({ objktId, src, title, creator, supply, priceText }) => {
  return (
    <>
      <audio
        controls
        src={src}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <CustomGridItem container spacing={2}>
        <Grid item xs={8} md={6}>
          <Link href={`https://teia.art/objkt/${objktId}`} target="_blank" rel="noreferrer" color="inherit" underline="hover">
            {(creator && creator.name) ? (
              <ImageListItemBar position="below" title={title} subtitle={<span>by: {creator?.name}</span>} />
            ) : (
              <ImageListItemBar position="below" title={title} />
            )}
          </Link>
        </Grid>
        <Grid item xs={8} md={6}>
          <Link>
            {priceText ? (
              <ImageListItemBar position="below" title={`${supply} editions`} subtitle={<span>{priceText}</span>} />
            ) : (
              <ImageListItemBar position="below" title={`${supply ? supply : '0'} editions`} />
            )}
          </Link>
        </Grid>
      </CustomGridItem>
    </>
  );
}

export default AudioItem;