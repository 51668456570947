import fetchGraphQlData from './fetchGraphql';
import queryGallery from '../queries/queryGallery';

export const getCsvData = () => {
  return new Promise((resolve, reject) => {
    fetch('https://sheets.googleapis.com/v4/spreadsheets/1CMjIN3QxgIHoPxqbNa0yifPFv-XgIGy2u8kYyVJU0PY/values/Wallets?key=AIzaSyCSjN9Zib2W3zF1GCDinMusLJQMYpUhMuY')
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log('ResponseJson', responseJson);
        const head = responseJson.values.shift();
        const result = [];
        responseJson.values.forEach((element, index) => {
          const obj = {};
          result.push(obj[head[0]] = element[0]);
        });
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getGallerieData = (address) => {
  return new Promise((resolve, reject) => {
    fetchGraphQlData(queryGallery, "creatorGallery", { "address": address }).then((result) => {
      if (result.data && result.data.hic_et_nunc_token) {
        resolve(result.data.hic_et_nunc_token);
      } else {
        reject(`empty getGallerieData ${address}`);
      }
    }).catch((error) => {
      reject(error);
    });
  });
}

export const getGalleries = (fileContent) => {
  return new Promise((resolve, reject) => {
    Promise.all(fileContent.map(data => {
      if (data) {
        return getGallerieData(data)
      }
    })).then((result) => {
      resolve(result);
    }).catch((error) => {
      reject(error);
    });
  });
}

export const concatGalleriesData = (galleriesData) => {
  let data = [];
  let objId = [];
  if (galleriesData.length) {
    galleriesData.forEach((element) => {
      if (element.length) {
        element.forEach((el) => {
          if (!objId.includes(el.id)) {
            objId.push(el.id);
            data.push(el);
          }
        });
      }
    });
  }

  return data;
}
