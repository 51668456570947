
const fetchGraphQlData = (operationsDoc, operationName, variables) => {
  return new Promise((resolve, reject) => {
    fetch("https://api.hicdex.com/v1/graphql", {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName
      })
    }).then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default fetchGraphQlData;