import React from 'react';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Link from '@mui/material/Link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImageItem = ({ objktId, src, title, supply, creator, priceText }) => {
  return (
    <Link href={`https://teia.art/objkt/${objktId}`} target="_blank" rel="noreferrer" color="inherit" underline="hover">
      <LazyLoadImage
        alt={title}
        effect="blur"
        placeholder={<span>Loading ...</span>}
        src={src} />
      {(creator && creator.name) ? (
        <ImageListItemBar position="top" title={title} subtitle={<span>by: {creator?.name}</span>} />
      ) : (
        <ImageListItemBar position="top" title={title} />
      )}
      {priceText ? (
        <ImageListItemBar position="below" title={`${supply} editions`} subtitle={<span>{priceText}</span>} />
      ) : (
        <ImageListItemBar position="below" title={`${supply ? supply : '0'} editions`} />
      )}
    </Link>
  );
}

export default ImageItem;