import React from 'react';
import { floor } from 'lodash';
import ImageItem from './imageItem';
import VideoItem from './videoItem';
import AudioItem from './audioItem';


const ListItem = ({ itemData }) => {
  let ipfsUrl = '#';

  if (itemData.artifact_uri) {
    ipfsUrl = itemData.artifact_uri.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }
  let price = 0;
  let priceText;
  if (itemData.swaps && itemData.swaps[0]) {
    if (itemData.swaps[0].price) {
      price = itemData?.swaps[0].price / 1000000;
    }
    if (itemData.swaps[0].price) {
      priceText = `${itemData?.swaps[0].amount_left} primary ${floor(price, 2)} xtz`;
    }
  }

  if (itemData.mime && itemData.mime.includes('video')) {
    return (
      <VideoItem objktId={itemData.id} src={ipfsUrl} title={itemData?.title} supply={itemData?.supply} creator={itemData?.creator} priceText={priceText} />
    );
  }

  if (itemData.mime && itemData.mime.includes('audio')) {
    return (
      <AudioItem objktId={itemData.id} src={ipfsUrl} title={itemData?.title} supply={itemData?.supply} creator={itemData?.creator} priceText={priceText} />
    );
  }

  return (
    <ImageItem objktId={itemData.id} src={ipfsUrl} title={itemData?.title} supply={itemData?.supply} creator={itemData?.creator} priceText={priceText} />
  );
};

export default ListItem;